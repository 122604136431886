//Now, we are going to style all our elements from the footer.js file from the footer folder
import styled from 'styled-components/macro';

//using this css to style our element container from the footer.js file
export const Container = styled.div`
  display: flex;
  padding: 70px 0;
  margin: auto;
  max-width: 1000px;
  flex-direction: column;
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

//using this css to style our element column from the footer.js file
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

//using this css grid to create a type of table with row for our element column from the footer.js file
export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 15px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

//We are styling the text of our link element from the footer.js file, so we use a not a div
export const Link = styled.a`
  color: #757575;
  margin-bottom: 20px;
  font-size: 14px;
  text-decoration: none;
`;

//We are styling the text of our Title element from the footer.js file, so we use p not a div
export const Title = styled.p`
  font-size: 16px;
  color: #757575;
  margin-bottom: 40px;
`;

//We are styling the text of our Text element from the footer.js file, so we use p not a div
export const Text = styled.p`
  font-size: 13px;
  color: #757575;
  margin-bottom: 40px;
`;

//We are styling our break element from the footer.js file, so we use a not a div
export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;



