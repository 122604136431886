import { useEffect, useState, useContext } from 'react';

import { FirebaseContext } from '../context/firebase';

//Here we need a target as argument; which will be the heroes and heroes types
//This will allow us to split our contents in different type and not just render them the same way
export default function useContent(target) {
  
    //This will be an array by default
    const [content, setContent] = useState([]);
    //we need to get firebase in here to access our collection on google firestore
    const { firebase } = useContext(FirebaseContext);

    //Look for the documentation of firebase to learn more about all these methods
    //target from the collection firestore will be the different collection we have in our google firestore online
    //By passing the target on our collection in line 20, our user will be able to show between our collection hero on firestore
    //so we will get our target with the .get and take a snapshot of it with .then
    useEffect(() => {
        firebase
            .firestore()
            .collection(target)
            .get()
            .then((snapshot) => {
            //we will map all the content snap we got, and spread that data; because we will have a bunch of content, so we need to map through it and select it with doc id 
            //we can use the docId for certain key; it's going to be uniq for each one since we are doing docs.map, each one will have its own; docid will be use for each slice item
            //This is React specific to use uniq id to get a specific content with the line of code line 29
            const allContent = snapshot.docs.map((contentObj) => ({
                ...contentObj.data(),
                docId: contentObj.id,
            }));

            setContent(allContent);
        })
        .catch((error) => {
            console.log(error.message);
        });
    }, [firebase, target]);

    //Target will be all of our series of heroes then we can return the content
    return { [target]: content };
  
}





