//This will be the style component for our 
import styled from 'styled-components/macro';

//When we click, we will be able to see the title
export const Title = styled.p`
  font-size: 24px;
  color: #e5e5e5;
  font-weight: bold;
  margin-left: 56px;
  margin-right: 56px;
  margin-top: 0;
`;

//We will always need to style a container to put everything we have from our component
//line 20, so this will be the media query for the first title
//last of type line 26 will have a margin-bottom
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  > ${Title} {
    @media (max-width: 1000px) {
      margin-left: 30px;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

//The rows where we are going to put each type of heroes to show our lists
//line 35, so if there is a flex direction, that flexdirection will be equal to row then add row, or else make it in a column
//line 38 says if we have align items,
//In the container, first of type will be assigned a media
export const Group = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection === 'row' ? 'row' : 'column')};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  

`;
//The same exact css codes as the above one just line 52-55 add a different responsive media 
//margin-top: -100px will push all group content to the extreme bottom half of the page
// export const Group = styled.div`
//   display: flex;
//   flex-direction: ${({ flexDirection }) => (flexDirection === 'row' ? 'row' : 'column')};
//   ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
//   ${({ margin }) => margin && `margin: ${margin}`};
//   > ${Container}:first-of-type {
//     @media (min-width: 1100px) {
//       margin-top: -100px;
//     }
//   }
// `;

//The description for each hero
export const SubTitle = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
  display: none;
`;

//Basic Text
export const Text = styled.p`
  margin-top: 5px;
  font-size: 10px;
  color: #fff;
  margin-bottom: 0;
  user-select: none;
  display: none;
  line-height: normal;
`;

//This is where we will be doing direction of the items to spread in a row format
export const Entities = styled.div`
  display: flex;
  flex-direction: row;
`;

//The style of the description
export const Meta = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  padding: 10px;
  background-color: #0000008f;
`;

//Style for the image and when users click on it
export const Image = styled.img`
  border: 0;
  width: 100%;
  max-width: 305px;
  cursor: pointer;
  height: auto;
  padding: 0;
  margin: 0;
`;
//The hover transition between the pictures for users to click on 
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.3);
    z-index: 99;
  }
  @media (min-width: 1200px) {
    &:hover ${Meta}, &:hover ${Text}, &:hover ${SubTitle} {
      display: block;
      z-index: 100;
    }
  }
  &:first-of-type {
    margin-left: 56px;
    @media (max-width: 1000px) {
      margin-left: 30px;
    }
  }
  &:last-of-type {
    margin-right: 56px;
    @media (max-width: 1000px) {
      margin-right: 30px;
    }
  }
`;

export const FeatureText = styled.p`
  font-size: 18px;
  color: white;
  font-weight: ${({ fontWeight }) => (fontWeight === 'bold' ? 'bold' : 'normal')};
  margin: 0;
  @media (max-width: 600px) {
    line-height: 22px;
  }
`;

//Since it is goint to be a hold, we will have it as dropdown;
export const Feature = styled.div`
  display: flex;
  flex-direction: row;
  background: url(${({ src }) => src});
  background-size: contain;
  position: relative;
  height: 360px;
  background-position-x: right;
  background-repeat: no-repeat;
  background-color: black;
  @media (max-width: 1000px) {
    height: auto;
    background-size: auto;
    ${Title} {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    ${FeatureText} {
      font-size: 14px;
    }
  }
`;

export const FeatureTitle = styled(Title)`
  margin-left: 0;
`;

//So that our feature can close; this needs to be a button since it is an action
//we style the icon image close with line 175
export const FeatureClose = styled.button`
  color: white;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  img {
    filter: brightness(0) invert(1);
    width: 24px;
  }
`;

export const Content = styled.div`
  margin: 56px;
  max-width: 500px;
  line-height: normal;
  @media (max-width: 1000px) {
    margin: 30px;
    max-width: none;
  }
`;

//Switch Maturity here to Popularity
// export const Maturity = styled.div`
//   background-color: ${({ rating }) => (rating >= 15 ? '#f44336' : '#2f9600')};
//   border-radius: 15px;
//   width: 28px;
//   line-height: 28px;
//   text-align: center;
//   color: white;
//   font-weight: bold;
//   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
//   margin-right: 10px;
//   font-size: 12px;
// `;