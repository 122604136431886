import React from 'react';
import Footer from '../components/footer/index';

// We are creating this components so that we can import our footer.js file from footer folder
// We will export all our elements(column, break, row, etc..) we created from the footer file of footer folder
export function FooterContainer() {
    return(
        <Footer>
            <Footer.Title>Questions? Contact us.</Footer.Title>
            <Footer.Break />
            <Footer.Row>
                <Footer.Column>
                    <Footer.Link href="#">FAQ</Footer.Link>
                    <Footer.Link href="#">Community</Footer.Link>
                    <Footer.Link href="#">News</Footer.Link>
                    <Footer.Link href="#">About</Footer.Link>
                </Footer.Column>

                <Footer.Column>
                    <Footer.Link href="#">Account</Footer.Link>
                    <Footer.Link href="#">Help Center</Footer.Link>
                    <Footer.Link href="#">Collab</Footer.Link>
                    <Footer.Link href="#">Contact Us</Footer.Link>
                </Footer.Column>

                <Footer.Column>
                    <Footer.Link href="#">Privacy</Footer.Link>
                    <Footer.Link href="#">Redeem Gifts</Footer.Link>
                    <Footer.Link href="#">Speed Test</Footer.Link>
                    <Footer.Link href="#">Terms of Use</Footer.Link>
                </Footer.Column>

                <Footer.Column>
                    <Footer.Link href="#">Media Center</Footer.Link>
                    <Footer.Link href="#">Legal Notices</Footer.Link>
                    <Footer.Link href="#">Cookie Preferences</Footer.Link>
                    <Footer.Link href="#">Opportunities</Footer.Link>
                </Footer.Column>
            </Footer.Row>
            <Footer.Break />
            <Footer.Text> Copyright &copy; MobaTech</Footer.Text>
          
        </Footer>
    );
}







