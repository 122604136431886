//This will be the first page users will hit everytime they log on to our web app
import React, { useState, useEffect, useContext } from 'react';


import { FirebaseContext } from "../context/firebase";
import { SelectProfileContainer } from "./profiles";
// To Import the footer at the bottom of our page
import { FooterContainer } from './footer';
//This is a library from React which will do the live search
// import Fuse from 'fuse.js';
//We are importing all the components that we need
// import { Card, Header, Loading, Player } from '../components';
import { Card, Header, Loading} from '../components';

//This will be to import the logo if u ever decide to do so
// import * as ROUTES from '../constants/routes';

//if u ever import a logo
// import logo from '../logo.svg';


export function BrowseContainer ({slides}) {


  //First you need to show what category, you are going to show when you log in on the page; we will show the Buffed Heroes
  const [category, setCategory] = useState('Buffed');
  //We need this to set our profiles; we want it to be an empty object here, because we are going to pass the object we have from line 22
  const [profile, setProfile] = useState({});
  //We set the loading first to true, because if you enter from the sign up path, this is expected to be true;
  const [loading, setLoading] = useState(true);
  //We create another UseState for our SearchTerm and set it to empty since we are going to create some custom logic inside our component index.js file for header folder
  //Since we have this const SearchTerm, we will use it as dependenci in the useEffect line 56 below
  const [searchTerm, setSearchTerm] = useState('');

  //line 44 slide rows which will host the small percentage of our heroes pull; we don't want to show the entire data, just show some and the rest will follow
  const [slideRows, setSlideRows] = useState([]);
  // const [slideRows, setSlideRows] = useReducer([]);

  const { firebase } = useContext(FirebaseContext);
  //We need to fetch all of our users from our firebase and firestore, '||' if current user doesn't exit, just past it an empty object
  const user = firebase.auth().currentUser || {};


  useEffect(() => {
    //This setTimeout we are given it (3000 = 3s); which means after that 3s; set Loading to false
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [profile.displayName]);

  //We wanna utilize the active slide rows(The option the user clicked on top) instead of passing the entirety of our database on the webpage; so we will just say by the way here are some heroes, now loop over them and search what u need.
  //Slides will come into 2 categories, depending on what the users clicked, that's what we will show at the bottom; from what selection the users clicked on top
  useEffect(() => {
    setSlideRows(slides[category]);
  },
  //if the users change the slide, set the slide rows again and if category changes, just rerender the same again
  [slides, category]);

  // We want a live search; to do it, we need a useEffect
  // useEffect(() => {
  //   console.log('sliderows', slideRows)
  //   console.log('slides', slides)
  //   console.log('category', category)
  //   //With Search Term changes, we want to do a search
    
  //   let results = slides[category];
  //   console.log('results0:', results)
  //   if ( results.length > 0){
  //     results = results[0].data.filter(x => {
  //       console.log(x.role);
  //       console.log( x.role.toLowerCase().indexOf(searchTerm));
  //       console.log((x.role.toLowerCase().indexOf(searchTerm) > -1));
  //       return (x.role.toLowerCase().indexOf(searchTerm) > -1);
        
  //     });
  //     results= [{title: category, data: results}];
  //   }
  //     console.log('results1:', results)
          
  //   if (slideRows.length > 0 && searchTerm.length > 0 && results.length > 0) {
  //     console.log('results2:', results)
  //     setSlideRows(results);
  //   } else {
  //     console.log('slides2:', slides[category])
  //     setSlideRows(slides[category]);
  //   }
       
  // }, [searchTerm]);


  // We want a live search; to do it, we need a useEffect
  useEffect(() => {
    //With Search Term changes, we want to do a search
    
    let results = slides[category];
    if ( results.length > 0){
      results = results[0].data.filter(x => {
        return (x.title.toLowerCase().indexOf(searchTerm) > -1);
        
      });
      results= [{title: category, data: results}];
    }
          
    if (slideRows.length > 0 && searchTerm.length > 0 && results.length > 0) {
      
      setSlideRows(results);
    } else {
      
      setSlideRows(slides[category]);
    }
       
  }, [searchTerm]);


  // useEffect(() => {
  //   //With Search Term changes, we want to do a search
  //   //This below show how fuse works, we need to pass SlideRows as argument which is where our data is, and pass different identifier keys whithin our data, which are role, title and such 
  //   const fuse = new Fuse(slideRows, { keys: ['data.genre','data.description', 'data.title', 'data.role'] });
  //   //THen for our results, we will create a set of result and map it to create new set of results
  //   const results = fuse.search(searchTerm).map(({ item }) => item);
  //     console.log('Here')
  //   //So if slideRows is greater than zero and search Tearm greater than 3, result.length is set to zero if we haven't found anything, or setSlideRows(set to results) so we can 1 or even 5 depending on matching results
  //   if (slideRows.length > 0 && searchTerm.length > 3 && results.length > 0) {
  //     setSlideRows(results);
  //   } else {
  //     setSlideRows(slides[category]);
  //   }
  // }, [searchTerm, slides, category, slideRows]);


        // Sample Search
        // const fuse = new Fuse(slideRows, { keys: ['data.genre','data.description', 'data.title', 'data.role'] });

        // const results = fuse.search('Hanabi');

        // console.log('results', results)


  //Here, we want that if there is a display name, we want to show a transition from the profile loading transition to the browser contenant
  //if we have profile.displayname will means someone has clicked on the profile, now we can make sure loading state has kicked off, so we will setloading back to false!!
  //so whe profile.displayname changes, we expect this setloading to be true , and setTimeout to kick off in 3second, after 3 second, setLoading come back to false, while this is active
  
  return profile.displayName ? (
    <>
      {/* So after loading, we want to release the body, so we can interact with the new page */}
      {loading ? <Loading src={user.photoURL} /> : <Loading.ReleaseBody />}


      {/* Compare to this below on 89, this should be use only when you are adding a background image to the background sunch as Karrie1 picture
      <Header src='Karrie1' dontShowOnSmallViewPort> */}
      <Header dontShowOnSmallViewPort>
        <Header.Frame>
            {/* This Group Div will seat on top of our page with these 2 selections, top10 and Nerfed; it will treat them as part of the same div and apply our css style*/}
            {/* We will pass multiple Group div for our userprofil, search button, etc... */}
          <Header.Group>
            {/* <Header.Logo to={ROUTES.HOME} src={logo} alt="Netflix" /> 
                line 77 state if category is set on "Top10", then applied true, it will check for active and if it is there, it will do frontweight bold 700 from our style; if it is was, then font wont change
                onclick will set the category to Top10, and trigger render of useEffect to change slide rows same with Nefed
            */}
            <Header.TextLink active={category === 'Buffed' ? 'true' : 'false'} onClick={() => setCategory('Buffed')}>
              Buffed
            </Header.TextLink>
            <Header.TextLink active={category === 'Nerfed' ? 'true' : 'false'} onClick={() => setCategory('Nerfed')}>
              Nerfed
            </Header.TextLink>
           </Header.Group>
          <Header.Group> 
            {/* We are setting our search icon here since we only have 1 element in this group
            we want to keep the reference of the search term first, then set the search term */}
            <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Header.Profile>
              <Header.Picture src={user.photoURL} />
              {/* In our Dropdown, we will have some picture and text(which will be userName) here below; even tho we have the picture in the header, we still put it in the dropdown because we want to show it when u are and aren't in the drop down */}
              <Header.Dropdown>
                <Header.Group>
                  <Header.Picture src={user.photoURL} />
                  <Header.TextLink>{user.displayName}</Header.TextLink>
                </Header.Group>
                <Header.Group>
                    {/* We introducing the onclick on here to kill the authentification, so that the user will get signed out */}
                  <Header.TextLink onClick={() => firebase.auth().signOut()}>Sign out</Header.TextLink>
                </Header.Group>
              </Header.Dropdown>
            </Header.Profile>
          </Header.Group>
        </Header.Frame>


         {/* This is if I ever want to put a background container where we can put our images and stuff and remember to go into card and header folder styles and remove the display none I put there!!   */}
        {/* <Header.Feature>
          <Header.FeatureCallOut></Header.FeatureCallOut> */}
          {/* <Header.Text>
            Karrie was trained by the Empire to become a war machine from when she was a child, because of her incredible talent. 
            After one battle, the seriously wounded Karrie fused with the consciousness of an alien prophet, so she knew the fate of the demise of her hometown, 
            and to find a way to break the prophecy, she left Alaghat.
          </Header.Text> */}
          {/* We want a button to view the entire description of our Hero */}
          {/* <Header.PlayButton>View</Header.PlayButton> */}
        {/* </Header.Feature> */}
      </Header>


      {/* Our card container will be below the header because that is where we will put each category and list of items 
            Card Group will map over our data
            We will get to see all of our data
        */}
      <Card.Group>
        {/* In slideitem, we can past the id "category" which was used to fetch our id */}
        {slideRows.map((slideItem) => (
          <Card key={`${category}-${slideItem.title.toLowerCase()}`}>
            {/* This is how we will get the title from "selection-filter.js" file in ultils folder to appear here when our users log in */}
            {/* We are getting our title from the selection-filter.js from ultis folder */}
            <Card.Title>{slideItem.title}</Card.Title>
            {/* Now, let's add the Entities or data for each title or genre, to have our heroes showing up */}
            <Card.Entities>
                {/* Here, we will do the slide items from above there, we can use that data from slide item from line 20 browse.js file from pages folder */}
              {slideItem.data.map((item) => (
                // "item = {item}" is crucial " here fro when u are moving from one category to the next to display
                <Card.Item key={item.docId} item={item}>
                  <Card.Image src={`/images/${category}/${item.title}/small.jpg`} />
                  {/* So that we can display the info like the title and description*/}
                  <Card.Meta>
                    <Card.SubTitle>{item.title}</Card.SubTitle>
                    <Card.Text>{item.role}</Card.Text>
                  </Card.Meta>
                </Card.Item>
              ))}
            </Card.Entities>
            {/* We have to pass the category now from feature folder */}
            <Card.Feature category={category}>
                {/* We are expecting to have a player in here */}
              {/* <Player>
                <Player.Button />
                <Player.Video src="/videos/Karrie.mp4" />
              </Player> */}
            </Card.Feature>
          </Card>
        ))}
      </Card.Group>
      <FooterContainer />



    </>


    
  ) : (
    //We are gonna need to pass users and profiles from the firebase and paste it here
    // We pass the Setprofile in here because we want to display the username from line 30 above
    <SelectProfileContainer user={user} setProfile={setProfile} />
  );
























}





