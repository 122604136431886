import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
//Export the golbal font site style body and head of our app to 
import {GlobalStyles} from './global-styles'
//This helps remove the weird styling that some internet browser applied directly to your app
import 'normalize.css';

//We are Importing our Firebase here from the file firebase.prod.js folder lib; Last one to uncomment, so that you will be able to transport all ur data to ur firebase storage
import {firebase} from './lib/firebase.prod';
//We are going to import our FirebaseContexxt from context folder
import { FirebaseContext } from './context/firebase';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <>

        <FirebaseContext.Provider value={{ firebase}}>
            <GlobalStyles />
            <App />
        </FirebaseContext.Provider>

    </>,
    
  
);


