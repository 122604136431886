import React from 'react';
import { LockBody, ReleaseBody, Spinner, Picture } from './styles/loading';

export default function Loading({ src, ...restProps }) {
  return (
    //We want to lockbody here because we don't want users to be able to scroll image, so to lock body will be => bodyoverflow: hidden, to release it => bodyoverflow: visible
    //our profile image will be in our spinner
    //LockBody will lcok the loading page so that user can't interact with it
    <Spinner {...restProps}>
      <LockBody />
      <Picture src={`/images/users/${src}.png`} data-testid="loading-picture" />
    </Spinner>
  );
}

//Since we need to release the body, we will create a Release Body Function
//line 17 will just put the background back to normal
Loading.ReleaseBody = function LoadingReleaseBody() {
  return <ReleaseBody />;
};