//This will be our header component

//We are defining the useState since we are going to use it
import React, { useState } from 'react';

import {Link as ReactRouterLink} from 'react-router-dom'

//import the elements
import {
  Container,
  Group,
  Background,
  Dropdown,
  Picture,
  Link,
  Search,
  Profile,
  FeatureCallOut,
  SearchIcon,
  SearchInput,
  ButtonLink,
  PlayButton,
  Text,
  Feature,
  Logo,
} from './styles/header';

export default function Header({ bg = true, children, ...restProps }) {
  return bg ? (
    <Background data-testid="header-bg" {...restProps}>
      {children}
    </Background>
  ) : (
    children
  );
}

//This is for each children for Header.js file for the style folder; That's how we get to style most of them
//First child will be a Frame
Header.Frame = function HeaderFrame({ children, ...restProps}) {
    return <Container {...restProps}>{children}</Container>;
  }
  
  //This child is for logo, and it will be use to get to home page, so we have to use a to 
  //This will allow us to past a logo on the header.js file in the container folder
  //Line 23 is where logo is taking the style we gave it in header.js file line 16 in folder header/styles
  Header.Logo = function HeaderLogo({ to, ...restProps }) {
    return (
        <ReactRouterLink to={to}>
            <Logo {...restProps} />
        </ReactRouterLink>
    )
  }
  
 //This will be for our button link  
 Header.ButtonLink = function HeaderButtonLink({ children, ...restProps}) {
    return <ButtonLink {...restProps}>{children}</ButtonLink>;
  }


  Header.Feature = function HeaderFeature({ children, ...restProps }) {
    return <Feature>{children}</Feature>;
  };

  //Thia is something similar to Frame; will be a sidebar looking like component
Header.Group = function HeaderGroup({ children, ...restProps }) {
  return <Group {...restProps}>{children}</Group>;
};


Header.Profile = function HeaderProfile({ children, ...restProps }) {
  return <Profile {...restProps}>{children}</Profile>;
};

Header.Feature = function HeaderFeature({ children, ...restProps }) {
  return <Feature>{children}</Feature>;
};

Header.Picture = function HeaderPicture({ src, ...restProps }) {
  return <Picture {...restProps} src={`/images/users/${src}.jpg`} />;
};

Header.Dropdown = function HeaderDropdown({ children, ...restProps }) {
  return <Dropdown {...restProps}>{children}</Dropdown>;
};

Header.TextLink = function HeaderTextLink({ children, ...restProps }) {
  return <Link {...restProps}>{children}</Link>;
};

Header.PlayButton = function HeaderPlayButton({ children, ...restProps }) {
  return <PlayButton {...restProps}>{children}</PlayButton>;
};

Header.FeatureCallOut = function HeaderFeatureCallOut({ children, ...restProps }) {
  return <FeatureCallOut {...restProps}>{children}</FeatureCallOut>;
};

Header.Text = function HeaderText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

//This will be the function of our Header Search Term;
//We have to set our SearchTerm;
//And of course, finishing up by setting a prop
Header.Search = function HeaderSearch({ searchTerm, setSearchTerm, ...restProps }) {
  //Here, we are setting the SearchActive originally to false;
  //so depending if the search is active we want to make a magnifing glasses if it is; from line 78
  //line 78, when u click on the search icon, we want to set the earch to active and pass the previous value and the opposite of false; when u doing building a search engine, u want to set the value first, then u reset the search to opposite value; in building search engine be careful of batching, if not u wont get the full experience ( u can have 3 or 4 line up false value  while u expecting them to switch each time)
  //look for Batching stare values or setting stare based on previous stare in  React
  //We insert an image
  //We set to the value the value of searchtearm which is wht we pass in
  //line 90 ONCHANGE METHOD, we want to get the target insert by the user, and set our search with that value
  //line 93, if Search is active, set it to searchActive
  const [searchActive, setSearchActive] = useState(false);

  return (
    <Search {...restProps}>
      <SearchIcon onClick={() => setSearchActive((searchActive) => !searchActive)} data-testid="search-click">
        <img src="/images/icons/search.png" alt="Search" />
      </SearchIcon>
      <SearchInput
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
        placeholder="Search Heroes"
        active={searchActive}
        data-testid="search-input"
      />
    </Search>
  );
};










