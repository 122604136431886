//This will be our filter component to filter our data
//We want to return a new object with hero selection; this will be an array; we need to filter the data out with .filter method
export default function selectionFilter({ Nerfed, Top10, Tank, Buffed, heroes  } = []) {

  // export default function selectionFilter({ Nerfed, Top10, Tank, Buffed, heroes  } = []) {

    return {
      Buffed: [
        { title: 'Buffed', data: Buffed?.filter((item) => item.genre === 'Buffed') },
      ],
      Nerfed: [
        { title: 'Nerfed', data: Nerfed?.filter((item) => item.genre === 'Nerfed') },
      ],
      Top10: [
        { title: 'Top10', data: Top10?.filter((item) => item.genre === 'Top10') },
      ],
      heroes: [
        { title: 'Fighter', data: Tank?.filter((item) => item.title === 'Fighter') },
        { title: 'Marksman', data: Tank?.filter((item) => item.title === 'Marksman') },
        { title: 'Mage', data: Tank?.filter((item) => item.title === 'Mage') },
        { title: 'Support', data: Tank?.filter((item) => item.title === 'Support') },
        { title: 'Tank', data: Tank?.filter((item) => item.title === 'Tank') },
      ]  
      
    };
  }



