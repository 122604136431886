import React from "react";

//Importing Accordion as usual when importing files  into our container folder files, we don't need the {}
import Accordion from '../components/accordion/index'
import faqsData from '../features/faqs.json'

//Pane is like a Frame form we are using from the cover.js file for us here

export function FaqsContainer () {
    return (
        <Accordion>
            <Accordion.Title>Frequently Asked Questions</Accordion.Title>
            {faqsData.map((item) => (
                <Accordion.Item key={item.id}>
                    <Accordion.Header>{item.header}</Accordion.Header>
                    <Accordion.Body>{item.body}</Accordion.Body>
                </Accordion.Item>
            ))}
            
        </Accordion>
    );
}










