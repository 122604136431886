//We are briging useState here because we want to be able to control the user input of email, check our error(for duplicate emails for instance, we want it to be 'empty' cause it is going to be a string) and the user password address with our useState element
//SInce we wrap our entire app in Firebase, we can use UseContext to bring data from firebase
import React, {useState, useContext} from "react";

//The we import the firebaseContext to access data here
import {FirebaseContext} from '../context/firebase';

//We are using useNavigate, so that React will know we made successful calls; allow us to go through different pages; fly users from one page to the next; we are going to set it with its own const as well
import { useHistory } from 'react-router-dom';
// import {useNavigate} from 'react-router-dom';

//We are importing our footer for this page also
import { FooterContainer } from "../containers/footer";
//We are importing Header container to make the sign in header
import {HeaderContainer} from "../containers/header";
//we need to import our form here
import { Form} from '../components'

//We need to import our Routes, so we can switch browse links
import * as ROUTES from '../constants/routes';



function Signin () {


    //useNavigate set up to unable user to fly from one page to the next
    // const history useNavigate of React v6 = useHistory() of v5;
    const navigate = useHistory();

    //We are instructuring the firebase context just to access it through the line below from line 22 index.js file in source folder
    const {firebase} = useContext(FirebaseContext);

    // The useState below for both const are a string ('') data type because we are expecting the users to enter things
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Check form input elements are valid; we can do it with a function
    // email and password
    //line 49 we want to set it to true or false with our function in line 20; Password or Email adress equal to 'empty', then it is true that it is invalid from line 49
    const isInvalid = password ==='' || emailAddress === '';

    //Trying to see if we can make the email verification not only to verify empty, but also validety with line 23
    // const isInvalid = () => {
        
    //     const regEx =  /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g   
    //         if (regEx.test(emailAddress)){
    //             setError('Email is Valid');
    //         }  
    //         else if (!regEx.test(emailAddress) && emailAddress === '')  {
    //             setError("Email is Not Valid");
    //         }
    //         else {
    //             setError('');
    //         }
    // }

    //We want to make sure Firebase works here after we sign in
    //We are going to create the authentification method of firebase in here
    //we will use the email and password const we created
    //This is going to be a promise, so we need a then method
    //We are pushing data to the browser with push
    //if there's error, then we want to cath it and deal with it like with our catch method; and we want to set the error to the error message that firebase will come up with; Usually we will have to write the catch error on the backend to give explicit error message s such as this user account isn't valide or password can't be used
    //We usually will use ReactRouter to do this type of push method from line 58-62, but it will be too complex and we will need to wait the compount to didmount
    const handleSignIn = (event) => {
        event.preventDefault();

        firebase
            .auth()
            .signInWithEmailAndPassword(emailAddress, password)
            .then(() => {

                navigate(ROUTES.BROWSE);

            })
            .catch((error) => {
                setEmailAddress('');
                setPassword('');
                setError(error.message)
            })


    };


    return (
        <>
            <HeaderContainer>
                <Form>
                    <Form.Title>Sign In</Form.Title>
                    {/* error handler line below this; if there is an error, go head and pass the error in the form so we can see it */}
                    {/* This error message will only appear when we make an error */}
                    {error && <Form.Error>{error}</Form.Error>}

                    {/* We can make our error looks like a dangerous alert with the line 29 code */}
                    {/* We want our method to be a 'POST' method here because we dealing with emails and password */}
                    {/* We are writing line 36 beacuse we dont want to do 'event...' to strip the input value */}
                    {/* We are going to have the same for the password as well, so we do the same in target in line 41 */}
                    <Form.Base onSubmit={handleSignIn} method='POST'>
                        <Form.Input
                            placeholder='Email address'
                            value={emailAddress}
                            onChange={({ target }) => setEmailAddress(target.value)}
                        />
                        <Form.Input
                            // We want this to blur out the password entered so we use 'type password' line 40
                            type='password'
                            autoComplete='off'
                            placeholder='Password'
                            value={password}
                            onChange={({ target }) => setPassword(target.value)}
                        />
                        {/* Our Submit Form, if it is valid submit, if it is not valid, then don't submit it. Line 49 */}
                        <Form.Submit disabled={isInvalid} type='submit'>Sign In</Form.Submit>
                    </Form.Base>
                    <Form.Text>
                        New to MobaTech? <Form.Link to='/signup'>Sign up now.</Form.Link>
                    </Form.Text>
                    <Form.TextSmall>This page is protected by Google reCAPTCHA to ensure you are not a robot. Learn more.</Form.TextSmall>
                </Form>
            </HeaderContainer>
            <FooterContainer />
        </>
    )

}


export default Signin;
