import React from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';


//This will listen to know which port users are on and if they are navigating to other links; Route create different ROUTES for other usages ike sign in and sign out.
//Exact bring you to the exact mathing router link
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importing all the js file paths from pages folder
import { Browse, Home, Signin, Signup } from './pages/index';

//This will allow us to go back and forth using different links
import * as ROUTES from './constants/routes';

//we are importing userRedirect function from routes.js file 
import {IsUserRedirect, ProtectedRoute} from './helpers/routes';

import { useAuthListener } from './hooks';

// import Home from './pages/home'

// import Signin from './pages/signin'

// import Browse from './pages/browse';

// import Signup from './pages/signup'



function App() {

  const { user } = useAuthListener();

  return (
    <>
    <Router>
      
        {/* <Routes> */}

            {/* <Route path="/signin/*" element ={
               'Redirect users to a specific browser path'
              <IsUserRedirect user={user} loggedInPath={ROUTES.BROWSE} path={ROUTES.SIGN_IN}>
                  <Signin />
              </IsUserRedirect>

            }/> 
         
            <Route path="/signup/*" element ={
              <IsUserRedirect user={user} loggedInPath={ROUTES.BROWSE} path={ROUTES.SIGN_UP}>
                <Signup />
              </IsUserRedirect>
            }/>


            <Route path="/browse" element={
                  'This will directly send none users to the browse page which is a protected route' 
              <ProtectedRoute user={user} path={ROUTES.BROWSE}>
                <Browse />
              </ProtectedRoute>
            }/>


            <Route path="*" element={
              <IsUserRedirect user={user} loggedInPath={ROUTES.BROWSE} path={ROUTES.HOME}>
                <Home />
              </IsUserRedirect>
            }/> */}




            {/* <Route exact path="/" element={<Home />} /> */}

            {/* <Route exact path="/" element={<Home />} /> */}


            {/* <Route path="/signin/*" element ={
              
              <IsUserRedirect user={user} loggedInPath={< Browse/>} path={<Signin />}>
                  <Signin />
              </IsUserRedirect>

            }/> 

            <Route path="/signup/*" element ={
              <IsUserRedirect user={user} loggedInPath={< Browse/>} path={<Signup />}>
                <Signup />
              </IsUserRedirect>
            }/>

            <Route path="/browse" element={
                  
              <ProtectedRoute user={user} path={< Browse/>}>
                <Browse />
              </ProtectedRoute>
            }/>

            <Route path="*" element={
              <IsUserRedirect user={user} loggedInPath={< Browse/>} path={<Home />}>
                <Home />
              </IsUserRedirect>
            }/> */}



        <Switch>
          <IsUserRedirect user={user} loggedInPath={ROUTES.BROWSE} path={ROUTES.SIGN_IN}>
            <Signin />
          </IsUserRedirect>
          <IsUserRedirect user={user} loggedInPath={ROUTES.BROWSE} path={ROUTES.SIGN_UP}>
            <Signup />
          </IsUserRedirect>
          <ProtectedRoute user={user} path={ROUTES.BROWSE}>
            <Browse />
          </ProtectedRoute>
          <IsUserRedirect user={user} loggedInPath={ROUTES.BROWSE} path={ROUTES.HOME}>
            <Home />
          </IsUserRedirect>
        </Switch>







        {/* </Routes> */}
      </Router>   
    </>
  );

}



export default App;
