//To use this entire component, we will import it into our appjs file in src folder
import React from 'react';

//we need this to redirect users(Naviguate React v6= Redirect v5)
// import { Routes, Route, Navigate } from 'react-router-dom';

import { Route, Redirect } from 'react-router-dom';

//we need two function here: one to check if the users are logged in or signed in
//1st fuction will redirect them to our app browser page with now their user log in info account such as profile pictures and stuff
//2nd function will protect our browser web pages like security

//we will use React Router Dom AUthentification for this 1st function


//If we have a user that signed in; we will use this to redirect them to another page;
//We will pass the user(to get user info) as argument; the (loggedInPath); we will take the children, and of course the rest
export function IsUserRedirect({ user, loggedInPath, children, ...rest }) {

  return (

    
      // <Routes>

        <Route
        //So with this Rest, we will exchange our original Routes from the App.js file in source folder to these Routes right here, to redirect the users
          {...rest}
          render={() => {
            //if there no user, just return original children; so we check for user object first; so with our ..rest, we can pass a "path of sign in", to locate if the users come through that path
            //Pathname line of code will help us check for the user path of sign in and verified if he is sign in or not; if they come in with path of sign in; we will redirect them and children will be the component of sign in
            //if they aren't users, just return a children components which will be a page such as sign in or sign up;
            if (!user) {
              return children;
            }

            //SO if it's a user, we want to go head and redirect them into the path of sign in; if user is logged in, rredirect them here.
            if (user) {
              return (
                <Redirect
                  to={{
                    pathname: loggedInPath,
                  }}
                />
              );
            }
            //We can always retrun null if none of these two conditions are met
            return null;
          }}
        />

      // </Routes>
     
  );
}

//Protected routes; how we will allow only users to be redirected to this protected paths which we have for only users!! 
export function ProtectedRoute({ user, children, ...rest }) {

  return (

    

      // <Routes>

      
        <Route
          {...rest}
          render={({ location }) => {
            //If they area users, just return the protected path as children; which is the browser page
            if (user) {
              return children;
            }
            
            //if they are no users, redirect = Navigate them to sign in page which we do with line 62-63; location is just a path name for react router
            if (!user) {
              return (
                <Redirect
                  to={{
                    pathname: 'signin',
                    state: { from: location },
                  }}
                />
              );
            }

            //if none of
            return null;
          }}
        />
        
      // </Routes>
    

  );
}







