import React from "react";
import * as ROUTES from '../constants/routes'
//This will be the header of our app
import {Header} from '../components'
// import Header from '../components/header/index'
// import { Home, Signin } from "../pages";

//We need to import our logo image if you ever have one; this is the inport link; just replace ur logo with logo1 in the src folder
// import logo from '../logo1.jpg'

//line 13 restProps allow us to pass anything we want like image, link, button; we are passing the logo by the grace of Restprops in index.js file in component/header folder
//line 14 just like in line 13, we can pass children



export function HeaderContainer ({ children}) {
    return (
        <Header>
            <Header.Frame>
                {/* if you ever have a logo to insert in the header, this is the div to use */}
                {/* <Header.Logo to={ROUTES.HOME} alt="MobaTech" src={logo}/> */}
                <Header.ButtonLink to={ROUTES.SIGN_IN}>Sign In</Header.ButtonLink>
            </Header.Frame>
            {children}
        </Header>
    
    )
}




