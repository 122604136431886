//We are briging useState here because we want to be able to control the user input of email, check our error(for duplicate emails for instance, we want it to be 'empty' cause it is going to be a string) and the user password address with our useState element
//SInce we wrap our entire app in Firebase, we can use UseContext to bring data from firebase
import React, {useState, useContext} from "react";

//The we import the firebaseContext to access data here
import {FirebaseContext} from '../context/firebase';

//We are using useNavigate, so that React will know we made successful calls; allow us to go through different pages; fly users from one page to the next; we are going to set it with its own const as well
import {useHistory} from 'react-router-dom';
// import {useNavigate} from 'react-router-dom';

//We are importing our footer for this page also
import { FooterContainer } from "../containers/footer";
//We are importing Header container to make the sign in header
import {HeaderContainer} from "../containers/header";
//we need to import our form here
import { Form} from '../components'

//We need to import our Routes, so we can switch browse links
import * as ROUTES from '../constants/routes';

//Here we need first the history, because when someone sign up, we want to redirect them to the browser page.


function Signup () {

    //for the history and the firebase context; we will useNavigate
    // const UseNavigate of React v6 = useHistory() of React v5;
    const history = useHistory();
    const { firebase } = useContext(FirebaseContext);

    //We want users to sign in with username, email and password;
    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    //To check our condition on how valid our user info are
    const isInvalid = userName === '' || password === '' || emailAddress === '';

    //This is the event on how to handle our Signup
    const handleSignup = (event) => {
      event.preventDefault();
    
      //now, we need to code on the firebase end
      //create the auth; userwithEmailandPassword
      //then method for when we have the result, we need to do "result.user" to display that particular username, so we will upgrade that displayname with a userName
      //PhotoUrl will also update the user image from preselected image we have (we have 5 of them, so it will pick one randomly)
      return firebase
        .auth()
        .createUserWithEmailAndPassword(emailAddress, password)
        .then((result) =>
          result.user
            .updateProfile({
              displayName: userName,
              photoURL: Math.floor(Math.random() * 5) + 1,
            })
            .then(() => {
              //nagivate of React dom v6 = history.push
              history.push(ROUTES.BROWSE);
            })
        )
        //we want to catch the error from our 2nd then method in line 58
        .catch((error) => {
          setUserName('');
          setEmailAddress('');
          setPassword('');
          setError(error.message);
        });
    };
  
    return (
      <>
        <HeaderContainer>
          <Form>
            <Form.Title>Sign Up</Form.Title>
            {/* If there is an error, then give them the form error; this will only display if there is an error. */}
            {error && <Form.Error>{error}</Form.Error>}

            {/* Form.base is our Form style, remember, we need it for our form, for username, email and password */}
            <Form.Base onSubmit={handleSignup} method="POST">
              <Form.Input
                placeholder="Username"
                value={userName}
                onChange={({ target }) => setUserName(target.value)}
              />
              <Form.Input
                placeholder="Email address"
                value={emailAddress}
                onChange={({ target }) => setEmailAddress(target.value)}
              />
              <Form.Input
                type="password"
                value={password}
                autoComplete="off"
                placeholder="Password"
                onChange={({ target }) => setPassword(target.value)}
              />
              <Form.Submit disabled={isInvalid} type="submit" data-testid="sign-up">
                Sign Up
              </Form.Submit>
            </Form.Base>
  
            <Form.Text>
              Already a user? <Form.Link to="/signin">Sign in now.</Form.Link>
            </Form.Text>
            <Form.TextSmall>
              This page is protected by Google reCAPTCHA to ensure you're not a robot. Learn more.
            </Form.TextSmall>
          </Form>
        </HeaderContainer>
        <FooterContainer />
      </>
    );

}









export default Signup;


