import React from 'react';

//We need to import everything we have declared so far that's not here
import { Container, Row, Column, Link, Title, Text, Break } from './styles2/footer';


export default function Footer({ children, ...restProps}) {

  return <Container {...restProps}>{children}</Container>
}

//We want to have a serie of row to display on our app footer, so we declare a function for that
Footer.Row = function FooterRow({children, ...restProps}) {
    return <Row {...restProps}>{children}</Row>;
};

//We want to have a serie of column to display on our app footer, so we declare a function for that
Footer.Column = function FooterColumn({children, ...restProps}) {
    return <Column {...restProps}>{children}</Column>;
};

//We want to have a serie of links so that we can change between pages; he want it to display on our app footer, so we declare a function for that
Footer.Link = function FooterLink({children, ...restProps}) {
    return <Link {...restProps}>{children}</Link>;
};

//We want to have a title; he want it to display on our app footer, so we declare a function for that
Footer.Title = function FooterTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>;
};

//We want to have a title; he want it to display on our app footer, so we declare a function for that
Footer.Title = function FooterTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>;
};

//We want to have some text as well; he want it to display on our app footer, so we declare a function for that
Footer.Text = function FooterText({children, ...restProps}) {
    return <Text {...restProps}>{children}</Text>;
};

//We want to have some break in between the text as well; he want it to display on our app footer, so we declare a function for that
Footer.Break = function FooterBreak({children, ...restProps}) {
    return <Break {...restProps}>{children}</Break>;
};



 




