//We use Macro so that in the Inspect it will give us the actual name of the component
import styled from "styled-components/macro";

//We can style a div, section, h1 or whatever you would like. Now, we are going to style the parent item in Cover.js file first, then its children
export const Item = styled.div`
    display: flex;
    border-bottom: 8px solid #222;
    padding: 50px 5%;
    color: white;
    overflow: hidden;
`;

//Direction in style helps you move the text to either left and right and the image also for the children item in Jumbotron in Cover.js file
export const Jumbotron = styled.div`

    display: flex;
    align-items: center;
    flex-direction: ${({direction}) => direction};
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    width: 100%;


    @media (max-width:700px){
        flex-direction: column;
    }


`;

//Here we are leaving a style open for all the children in the container App.js file that we are exporting into the file Cover.js file in Cover.Container
//if you would like to be more specific in ur item styling for a const Item fromCover.js file to style it, and we said last of type, which is last 3rd image in fixture.json file
export const Container = styled.div`
    @media (max-width: 700px) {
        ${Item}: last-of-type h2 {
            
        }
    }

`;

export const Pane = styled.div`
    width: 50%;

    @media (max-width: 700px) {
        width: 100%;
        padding: 0 45px;
        text-align: center;
    }
`;

export const Title = styled.h1`
    font-size: 50px;
    line-height: 1.1;
    margin-bottom: 8px;

    @media (max-width: 600px) {
        font-size: 35px;
    }
`;

export const SubTitle = styled.h2`
    font-size: 26px;
    font-weight: normal;
    line-height: normal;

    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

export const Image = styled.img`
    max-width: 100%;
    height: auto;
`;

//Here we are referecing const Item fromCover.js file to style it, and we said last of type, which is last 3rd image in fixture.json file
// export const Container = styled.div`
//     @media (max-width: 1000px) {
//         ${Item}: last-of-type h2 {
//             margin-bottom: 50px;
//         }
//     }

// `;

