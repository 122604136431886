import styled from "styled-components/macro";
import {Link as ReactRouterLink } from 'react-router-dom';

//The CSS style to be applied for the background image
//line 9 background is for: if the background image is a source, go to the folder location  and use that image; Because we our using a static website and not a dynamic one, u can use some content management system without having to use developers to always change an image; if there's no background image, we provide them the basic one
//We will insert a background image in here; line 14, we are insering a prop
//line 15 is for background to not show on small screen, so if small screen present, then set background to none.
export const Background = styled.div`
    display: flex;
    flex-direction: column;
    background: none;

    

`;
//Exact same code as the one to the top, but this one allows us to paste a background image; the above as background none
//SmallViewPort is the display change for when the user decrease the size screen or if the users are on a mobile device; 
// export const Background = styled.div`
//     display: flex;
//     flex-direction: column;
//     background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.35)), url(${({ src }) => (src ? `../images/catalog1/${src}.jpg` : '../images/catalog1/Screenshot2.jpg')}) top left / cover;
//     no-repeat;

//     @media (max-width: 1100px) {
//     ${({ dontShowOnSmallViewPort }) => dontShowOnSmallViewPort && `background: none;`}
//     }

// `;

//The css sstyle for our content container
export const Container = styled.div`
    display: flex;
    margin: 0 56px;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    a {
    display: flex;
    }
    @media (max-width: 1000px) {
    margin: 0 30px;
    }
`;

//This will be the css for our logo;
//Media screen is for when the screen go to 1449px, then the image will flex to a height and width 45 & 167px;
//This is for when you will ever insert a logo image; I already pre write the css styling for you, u welcome!!
export const Logo = styled.img`
    height: 36px;
    width: 134px;
    margin-right: 40px;

    @media (min-width: 1449px) {
    height: 45px;
    width: 167px;
    }
`;

//This is an element from React-Router, and we are going to apply a link style to it
export const ButtonLink = styled(ReactRouterLink)`
    display: block;
    background-color: #e50914; 
    width: 84px;
    height: fit-content;
    color: white;
    border: 0;
    font-size: 15px;
    border-radius: 3px;
    padding: 8px 17px;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;

    &:hover {
    background: #f40612;
    }
`;

//We want to reuse the style from our container above, so we pass COntainer has an argument here;
//Be careful line 76-77 will make your entire page content disappear when then size of the screen get reduced to 900px because of display none
//Even tho display disappear at screen size 1100px, we want to keep it like that to not make things look rediculous
//Change line 78 width to 1100px when u done editing
export const Feature = styled(Container)`
  padding: 150px 0 500px 0;
  flex-direction: column;
  align-items: normal;
  width: 50%;
  @media (max-width: 950px) {
    display: none;
  }
`;

//style for link
//line 90: so if it's active, we have to destructure like in "=>", the scale should either be 700 or normal
//hover will be bold
export const Link = styled.p`
  color: #fff;
  text-decoration: none;
  margin-right: 30px;
  font-weight: ${({ active }) => (active === 'true' ? '700' : 'normal')};
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

//We styled our group div from index.js file of header folder
export const Group = styled.div`
  display: flex;
  align-items: center;
`;

//We are setting a conditional type of style here
//line 116, if we have an active state, then active is === true set margin to 10px; 
//line 118 Active is equal === True, then increased the margin from the left
export const SearchInput = styled.input`
  background-color: rgba(64, 64, 64, 0.5);
  color: white;
  border: 1px solid white;
  transition: width 0.5s;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  margin-left: ${({ active }) => (active === true ? '10px' : '0')};
  padding: ${({ active }) => (active === true ? '0 10px' : '0')};
  opacity: ${({ active }) => (active === true ? '1' : '0')};
  width: ${({ active }) => (active === true ? '200px' : '0px')};
  &:focus {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

//Search will be a div
export const Search = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: white;
    cursor: pointer;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

export const SearchIcon = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  height: 32px;
  width: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    filter: brightness(0) invert(1);
    width: 16px;
  }
`;

//Since we are passing a source from line 77 of browse.js file from container folder, this has to be a button type of style
export const Picture = styled.button`
  background: url(${({ src }) => src});
  background-size: contain;
  border: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

//Here Display None for the dropdown because we don't want it to be shown just yet, but to only appear when the user hover on it
//This is the hoover action when user over the small profile image;  we don't want our dropdown to just show, we want it to display (none) at first, tille users click on it
//Group method in line 179 makes the last of type(sign out) and we want to target the link in there from the style link above; to have a pointer when the users click on it; we dont want a margin bottom
//So the Effect on the Link, will make the users be able to click on the Sign out, but not be able to click on the user display small name
//Font Size of the link in P
//Line 195 we want some space margin between both of our button display name and our icon display user 
export const Dropdown = styled.div`
  display: none;
  position: absolute;
  background-color: black;
  padding: 10px;
  width: 100px;
  top: 32px;
  right: 10px;
  ${Group}:last-of-type ${Link} {
    cursor: pointer;
  }
  ${Group} {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    ${Link} {
      cursor: pointer;
    }
    ${Picture} {
      cursor: default;
    }
  }
  button {
    margin-right: 10px;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

//We put the dropdow so it will display when the users hover
export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: relative;
  button {
    cursor: pointer;
  }
  &:hover > ${Dropdown} {
    display: flex;
    flex-direction: column;
  }
`;

//This will be the styling for our Header of featurecallout in line 85 HeaderCOntainer in browse.js file for container folder
export const FeatureCallOut = styled.h2`
  color: white;
  font-size: 50px;
  line-height: normal;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin: 0;
`;

export const Text = styled.p`
  color: white;
  font-size: 22px;
  line-height: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
`;

//The style for the video play button css style
export const PlayButton = styled.button`
  box-shadow: 0 0.6vw 1vw -0.4vw rgba(0, 0, 0, 0.35);
  background-color: #e6e6e6;
  color: #000;
  border-width: 0;
  padding: 10px 20px;
  border-radius: 5px;
  max-width: 130px;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: #ff1e1e;
    color: white;
  }
`;









