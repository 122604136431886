//This will be the main component for our car

import React, { useState, useContext, createContext } from 'react';

import {
  Container,
  Group,
  Title,
  SubTitle,
  Text,
  Feature,
  FeatureTitle,
  FeatureText,
  FeatureClose,
  // Maturity,
  Content,
  Meta,
  Entities,
  Item,
  Image,
} from './styles/card';

export const FeatureContext = createContext();

export default function Card({ children, ...restProps }) {
  
    
    //We want to control if we will see the feature or not, when someone click on the card and it drop down the description and more info
    //We will pass here a boolean since we trying to do show description or dont show description, and we will start with not showing description
  const [showFeature, setShowFeature] = useState(false);
  //Here we want users to be able to click on one item, then it shows the description, then when user click on the next item, this will close up the previous item they had selected
  //We will pass an object here
  const [itemFeature, setItemFeature] = useState({});

  return (
    <FeatureContext.Provider value={{ showFeature, setShowFeature, itemFeature, setItemFeature }}>
      <Container {...restProps}>{children}</Container>
    </FeatureContext.Provider>
  );
}

Card.Group = function CardGroup({ children, ...restProps }) {
  return <Group {...restProps}>{children}</Group>;
};

Card.Title = function CardTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Card.SubTitle = function CardSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Card.Text = function CardText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

//So we want a card holder to hold the cards; then we will have title like documentaries and children title; THen, we will have an entitie for each one, we will have items inside
Card.Entities = function CardEntities({ children, ...restProps }) {
  return <Entities {...restProps}>{children}</Entities>;
};

//Subtitles and text
Card.Meta = function CardMeta({ children, ...restProps }) {
  return <Meta {...restProps}>{children}</Meta>;
};

//Here is where we will store our item
//Line 69, we are setting feature
//So we passed Item as a props here and each time, we can mrk this item each time and allow us to showcase that info with a dropdown
Card.Item = function CardItem({ item, children, ...restProps }) {
  const { setShowFeature, setItemFeature } = useContext(FeatureContext);

  return (
    <Item
    //We will set all the item feature from firebase and on click will fetch them
    //For each item clicked, we will get the info and store that info into the setShowFeature and make it visible;
      onClick={() => {
        setItemFeature(item);
        setShowFeature(true);
      }}
      {...restProps}
    >
      {children}
    </Item>
  );
};

//Setting images as props
Card.Image = function CardImage({ ...restProps }) {
  return <Image {...restProps} />;
};

//Here we need the category, so we can access the folder
// we want to use the const to showfeature and the itemfuture and 
Card.Feature = function CardFeature({ children, category, ...restProps }) {
  const { showFeature, itemFeature, setShowFeature } = useContext(FeatureContext);

  //Here return show feature when showFeature is set to true
  return showFeature ? (
    //This is to get the image from our storage folder
    //Since we init the restProps on line 96 above, we have to spread it here below in line 103
    <Feature {...restProps} src={`/images/${category}/${itemFeature.title}/small.jpg`}>
      {/* We need content below our pictures */}
      <Content>
        <FeatureTitle>{itemFeature.title}</FeatureTitle>
        <FeatureText>{itemFeature.description}</FeatureText>
        {/* <FeatureText>Counter: {itemFeature.counter}</FeatureText> */}
        <div className='counterSub'>Counter: {itemFeature.counter}</div>
        {/* This will shut off the feature when the user click to close it */}
        <FeatureClose onClick={() => setShowFeature(false)}>
          <img src="/images/icons/close-icom.png" alt="Close" />
        </FeatureClose>

          {/* Within style component, you can past props, like specific css props */}
        <Group margin="30px 0" flexDirection="row" alignItems="center">
          {/* This is just to set a maturity option; here we can set it to popularity instead of maturity and add a popularity attribute in our seed.js file for all the heroes and 
          We will have to switch Maturity to popularity here below and in the card.js file in styles/card folder
          <Maturity rating={itemFeature.maturity}>{itemFeature.maturity < 12 ? 'PG' : itemFeature.maturity}</Maturity> */}

            {/* This is how u can pass props like attribute for ur css using on character of our role title */}
          <FeatureText fontWeight="bold">
            {/* We are taking the first type character of role title name and slice it */}
            {itemFeature.role.charAt(0).toUpperCase() + itemFeature.role.slice(1)}
          </FeatureText>
        </Group>
          {/* We want the maturity in the content div, so we close it down there after maturity in line 127 and we passing children because we want the video to play;  */}
        {children}
      </Content>
    </Feature>
  ) : null;
};



