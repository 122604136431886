import React from 'react';
import { Container, Item, Jumbotron, Pane, Title, SubTitle, Image } from './styles/Jumbotron';

//if no direction is passed, we are defaulting it to a row
export default function Cover ({ children, direction = 'row', ...restProps}) {

  return (
    //Item = Item & Inner = Jumbotron
    <Item {...restProps}>
      <Jumbotron direction={direction} >
        {children}
      </Jumbotron>
    </Item>

  )

}

//This is for each children into our Cover.Container in App.js file; That's how we get to style most of them
Cover.Container = function CoverContainer ({ children, ...restProps}) {
  return <Container {...restProps}>{children}</Container>
}

//We are initializing each children variables from the different app.js file parent 
Cover.Pane = function CoverPane ({ children, ...restProps}) {
  return <Pane {...restProps}>{children}</Pane>
}

Cover.Title = function CoverTitle ({ children, ...restProps}) {
  return <Title {...restProps}>{children}</Title>
}

Cover.SubTitle = function CoverSubTitle ({ children, ...restProps}) {
  return <SubTitle {...restProps}>{children}</SubTitle>
}

//Image doesn't have any children coming from the fixture.json like the rest do, so it will be different
Cover.Image = function CoverImage ({ children, ...restProps}) {
  return <Image {...restProps} />
}

