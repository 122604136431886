import React from 'react';
import {BrowseContainer} from '../containers/browse';
import { useContent } from '../hooks';
import selectionFilter  from '../utils/selection-filter';

export default function Browse() {

  const { heroes } = useContent('heroes');
  const { Buffed } = useContent('Buffed');
  const { Nerfed } = useContent('Nerfed');
  const { Top10 } = useContent('Top10');
  //Here, we combined all the categories within Selection filter
  const slides = selectionFilter({ Buffed, heroes, Nerfed, Top10  });
  // const slides = selectionFilter({ Buffed, heroes, Nerfed, Top10 });

  //We are calling our browser container here; from above line 17, we pass it down in slides to => slides, so we will have the data inside here
  return <BrowseContainer slides={slides} />;

}




