//This will be showing who is logged on our app
import React from 'react';
//we want to render the header
import { Header, Profiles } from '../components';

//This is for the Logo if u ever decide to use it
// import * as ROUTES from '../constants/routes';


//To see if we have an active user or not
export function SelectProfileContainer({ user, setProfile }) {
  return (
    <>

       {/* we pass false in here because we dont want the background  */}
      <Header bg={false}>
        <Header.Frame>
          {/* <Header.Logo to={ROUTES.HOME} src={logo} alt="Mobatech" /> */}
        </Header.Frame>
      </Header>

      <Profiles>
        <Profiles.Title>Which user is logged on?</Profiles.Title>
        {/* For our Profile list, we will just add one profile for now!! */}
        <Profiles.List>
          <Profiles.User
            //So after user clicked, go head and set profile to displayname; from User.displayName and the PhotoUrl of User.photourl
            onClick={() => setProfile({ displayName: user.displayName, photoURL: user.photoURL })}
            data-testid="user-profile"
          >
            <Profiles.Picture src={user.photoURL} />
            <Profiles.Name>{user.displayName}</Profiles.Name>
          </Profiles.User>
        </Profiles.List>
      </Profiles>
    </>
  );
}