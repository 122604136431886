//We want this to be the style file for our entire app body web page

import { createGlobalStyle } from "styled-components";


export const GlobalStyles = createGlobalStyle`


        html, body {
            height: 100%;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #000000;
            color: #333333;
            font-size: 16px;
    }
`;

