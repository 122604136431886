//jumbo.js = jumbotron.js

import React from 'react';
import {Cover} from '../components';
import fixtureData from '../features/fixture.json';
// import { Jumbo } from '../components/sidebar';

// //Importing the data from our json fixture file and we going to map all the data to get each item
// //Cover will give the direction to each item in the jumbotron aaociated from the direction in the fixture json file
// //Pane is like a div 

export function CoverContainer() {

  return (

    <Cover.Container>

      {fixtureData.map((item) => (

        <Cover key={item.id} direction={item.direction}>
          <Cover.Pane>
            <Cover.Title>{item.title}</Cover.Title>
            <Cover.SubTitle>{item.subTitle}</Cover.SubTitle>
          </Cover.Pane>
          <Cover.Pane>
            <Cover.Image src={item.image} alt={item.alt}/>
          </Cover.Pane>
        </Cover>

      ))}

    </Cover.Container>
    
  );

}














