//We need to set our accordion first here with this "index.js" file.
//Then, we wrap our function Accorion into a Container to style it properly; And style the inner children.
//The Children elements are all the items element from the faqs.js file in our container.
//Within them, we have items such as Title, body or Item.
//in Item we pass key "id" cause he will map through an array of "faqsData from fixture folder" line 14-19 in the faqs.js file in our container.
//We use the Header and body keys but also the Header component here in line 50(inside these items, we passing the children, plus the children and restprops, so whoever can inside item can use them)
//He will press the key into this here, and wrap it into context; so it pass the key(in faqs file from container line 15), and spread it into line 26-32 of Index.js file of Accordion folder, and we wrap it into "Context in line 30-34", which will share the div between each item
//Then, we pass {..restProps} because we want people to pass whatever they like.


import React, { useState, useContext, createContext } from 'react';
//importing the styles for our item elements
import { Container, Title, Item, Inner, Header, Body } from './styles/accordion';

//Since we wanted to import toggle inside the header, we have to declare it on the outside so it will be accessible
const ToggleContext = createContext();

export default function Accordion({ children, ...restProps }) {
  return (
    <Container {...restProps}>
      <Inner>{children}</Inner>
    </Container>
  );
}

//For the Accordion which will be our FQAS page, we will need a main Title, sub Title, headers and body items
//This will be for our main title
Accordion.Title = function AccordionTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

//This items will give the users the opportunity to toggle between options
//We want toggle inside the Accordion.Header, since we want the header to change icons on a click
//Toggle here is set as false, and when passed we want it to switch to true => so ToggleContext.Provider will be use to set this action;
//we use set for actions
//Each our Item using Context as a provider
Accordion.Item = function AccordionItem({ children, ...restProps }) {
  //We are starting the toggle show to false, so nothing will show, no description display yet  
  const [toggleShow, setToggleShow] = useState(false);

  return (
    <ToggleContext.Provider value={{ toggleShow, setToggleShow }}>
      <Item {...restProps}>{children}</Item>
    </ToggleContext.Provider>
  );
};

//Header; when users click on it, it going to switch icons, so we need the onclick
//So we call a const since we wanted athe toggle insisde here
//when this is called, we wanna wrap Header as a children of Item above, so to do it we do the "Const{}"
//We want to Close the Toggle if it is either open or close on the click
Accordion.Header = function AccordionHeader({ children, ...restProps }) {
  const { toggleShow, setToggleShow } = useContext(ToggleContext);

  return (
    //Close the toggle when it is open, or open it when it's closed is the code in {body}
    //{body} just take the previous state of toggle and set it to the inverse
    //line 53 => we want a space between opening and closing option of the header
    //We add onclick event on toggle show here to switch it
    //And we spread it back to the rest of the children on here
    //if toggle show is true show close, if it is false show true
    <Header onClick={() => setToggleShow(!toggleShow)} {...restProps}>
      {children}
      {toggleShow ? (
        <img src="/images/icons/close-icom.png" alt="Close" />
      ) : (
        <img src="/images/icons/add-icom.png" alt="Open" />
      )}
    </Header>
  );
};

Accordion.Body = function AccordionBody({ children, ...restProps }) {
  const { toggleShow } = useContext(ToggleContext);

  return (
    //So when toggle shows true, we need the body to diplay content, else it will close the body content description
    <Body className={toggleShow ? 'open' : 'closed'} {...restProps}>
      <span>{children}</span>
    </Body>
  );
};

