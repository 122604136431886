//We need a listener on our browser to tell us if we are having a user logging in on our web app or not; we will create one here

import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';

//We will be calling this function either on App.js or index.js file to just sit there and listen
export default function useAuthListener() {

  //no matter how long the users stay logged in, we want this to listen so the browser can keep up with users and not shutdown.
  //JSonParse to check to see if users still logged in with user authentification; so now, we need to check if there is a user in our storage, so we have to store in it any particular action, either logged in or out  
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')));
  //Context so we can get Firebase out of here, so it can listen also on the authentification exchange; if it was another back end, we will use it here also
  const { firebase } = useContext(FirebaseContext);

  //UseEffect will run on the first eteration of this application; as soon as we launch the app, we want this to start listening and sit on the browser; bind it asap
  //Useffect basically will listen on the first hit of the page
  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged((authUser) => {
        //So if there is a user, we want to store that in our local storage; stringify it means we are storing user authentification as a string value
        if (authUser) {
        localStorage.setItem('authUser', JSON.stringify(authUser));
        setUser(authUser);
      } 
      //Else if we dont have any user, we want to remove the auth user item that just logged in on our webbrowser off of the listener
      else {
        localStorage.removeItem('authUser');
        setUser(null);
      }
    });

    //Since this is a listener, in React u always want to end with cleaning up Listener after it mounted with code below
    //Read about listener in React; Errors can be close; multiply plex component touching one and another or transioning from one page to the other 
    return () => listener();
  }, []);

  //if we have to call the function on line 11; we need to call user down here
  return { user };
}


