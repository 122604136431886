import React from "react";
import '../App.css'


//Importing the data from our json fixture file and we going to map all the data to get each item
//Cover will give the direction to each item in the jumbotron aaociated from the direction in the fixture json file
//Pane is like a div 
import { FooterContainer } from '../containers/footer';
import {CoverContainer} from "../containers/Jumbo"
import {FaqsContainer} from '../containers/faqs'
import {HeaderContainer} from "../containers/header";

export default function Home() {
    return (
        <>
            <HeaderContainer>
                <div className="welcomeInk">MobaTech</div>
            </HeaderContainer>

            <CoverContainer />
            <FaqsContainer />
            <FooterContainer />
        </>
    );
}







