//This will be our Firebase production version
//Importing Firebase itself
//import Firebase from 'firebase/app';
import Firebase from 'firebase/compat/app';

//import the firebase firestore
// import 'firebase/firestore';
import 'firebase/compat/firestore';

//We will want the authentification feature of firebase, so we have to import it
// import 'firebase/auth';
import 'firebase/compat/auth';

//We need to import our seed file; we only want to import this once if not it will create duplicate
//I will comment this line 16 as well because I already copied the data in my firestore online, so I don't need to copy it again each time I save it
// import {seedDatabase} from '../seed';


//we need a config here for our firebase and store the data in our firestore
const config = {
    apiKey: "AIzaSyBy_UxnqZUCfcO8rC74Y4erEIDMCjS6MkQ",
    authDomain: "moba-tech-1.firebaseapp.com",
    projectId: "moba-tech-1",
    storageBucket: "moba-tech-1.appspot.com",
    messagingSenderId: "100810063719",
    appId: "1:100810063719:web:c65080e26c75d20575f9c2",
    measurementId: "G-KH8K2ZZS6R"
  };

//Then firebase will initialize our app with this config; we need this line to initialize our config
const firebase = Firebase.initializeApp(config);


//Importing our data from the seed.js file from the src folder
//This line below of code should only be run and saved once; comment it out or remove it as soon as u saved it and run it once
//I will comment the line 44 cause I don't want to duplicate the data everytime i saved my project.
// seedDatabase(firebase);


// You have to go in the Index.js file from the src folder and uncomment the line 11 and uncoment both line on top if you want to import ur data


// export  {Firebase};
export { firebase };